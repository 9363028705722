import React from 'react';
import PropTypes from 'prop-types';
import SDGTooltip from './SDGTooltip';
import '../../assets/styles/sdgImageFilter.css';
import getStaticImageURL from '../../shared/getStaticImageURL';

const SDGImageFilter = ({ SDGData, sdg, handleSDGChange }) => {
  return (
    <span className="SDGFilter">
      <ul>
        {(SDGData || []).map(value => {
          return (
            <SDGTooltip key={value.sdgid} sdg={value}>
              <li>
                <input
                  type="checkbox"
                  checked={sdg.indexOf(value.sdgid) !== -1}
                  onChange={handleSDGChange(value.sdgid)}
                  id={'sdgid' + value.sdgid}
                />
                <label htmlFor={'sdgid' + value.sdgid}>
                  <img
                    alt="value.sdgid"
                    src={getStaticImageURL(`SDG${value.sdgid}.png`)}
                  />
                </label>
              </li>
            </SDGTooltip>
          );
        })}
      </ul>
    </span>
  );
};

SDGImageFilter.propTypes = {
  SDGData: PropTypes.array,
  sdg: PropTypes.any,
  handleSDGChange: PropTypes.func,
};

export default SDGImageFilter;
