import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import { css } from '@emotion/react';
import Slide from '@material-ui/core/Slide';
import theme from '../../../config/theme';

const snackBarStyles = css`
  flex-grow: initial;
  min-width: 288px;
  color: #fff;
  display: flex;
  padding: 10px 16px;
  flex-grow: 1;
  flex-wrap: wrap;
  font-size: 0.875rem;
  align-items: center;
  font-family: ${theme.fontFamily.heading};
  font-weight: ${theme.fontWeight.heading};
  line-height: 1.43;
  border-radius: 4px;
  letter-spacing: 0.01071em;
  background-color: #498fe0;
  svg {
    padding: 3px;
    color: #ee2737ff;
    margin-right: 6px;
  }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = 'Transition';

const SnackBarComponent = ({ ...props }) => {
  const {
    openSnack,
    setOpenSnack,
    openSnackFav,
    setOpenSnackFav,
    message,
    icon,
  } = props;

  const handleSnackbarClose = () => {
    setOpenSnack(false);
    setOpenSnackFav(false);
  };

  return (
    <Snackbar
      TransitionComponent={Transition}
      autoHideDuration={2000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={openSnack || openSnackFav}
    >
      <span css={snackBarStyles}>
        {icon} {message}
      </span>
    </Snackbar>
  );
};

SnackBarComponent.propTypes = {
  Transition: PropTypes.func,
  openSnack: PropTypes.bool,
  setOpenSnack: PropTypes.func,
  openSnackFav: PropTypes.bool,
  setOpenSnackFav: PropTypes.func,
  message: PropTypes.any,
  icon: PropTypes.any,
};
export default SnackBarComponent;
