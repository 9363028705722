import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';

const sdgStaticData = require('../../../static/data/sdgData.json');

const SDGDescTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#fff',
    padding: '10px',
    textAlign: 'left',
    borderRadius: '5px',
    maxWidth: '250px',
    fontSize: '12px',
  },
  arrow: {
    color: '#fff',
  },
}))(Tooltip);

const description = {
  padding: '0px',
  margin: '0',
  textAlign: 'left',
  color: '#3C4858',
};

const SDGTooltip = ({ children, sdg }) => {
  const { t } = useTranslation();
  let singleSDG =
    (sdgStaticData || []).find(SDG => SDG.sdgID === sdg.sdgid) || {};
  return (
    <SDGDescTooltip
      title={
        <React.Fragment>
          <div
            className="custom-tooltip"
            style={{ padding: '0px', textAlign: 'left' }}
          >
            <p
              className="label"
              style={{
                padding: '0px',
                margin: '0',
                textAlign: 'left',
                color: singleSDG.color,
              }}
            >{`${t('goal')} ${sdg.sdgid} : ${sdg.sdgTitle}`}</p>
            <p
              className="intro"
              style={description}
            >{`${sdg.sdgDescription}`}</p>
          </div>
        </React.Fragment>
      }
    >
      {children}
    </SDGDescTooltip>
  );
};

SDGTooltip.propTypes = {
  SDGData: PropTypes.array,
  sdg: PropTypes.any,
  handleSDGChange: PropTypes.func,
  children: PropTypes.any,
};

export default SDGTooltip;
