import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { css } from '@emotion/react';

const CustomTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <div
        className="custom-tooltip"
        style={{ padding: '0px', textAlign: 'left' }}
      >
        <p
          className="intro"
          style={{
            padding: '0px',
            margin: '0',
            textAlign: 'left',
            fontSize: '12px',
            color: '#FFF',
          }}
        >{`${payload[0].payload.name}`}</p>
        <p
          className="intro"
          style={{
            padding: '0px',
            margin: '0',
            textAlign: 'left',
            fontWeight: '600',
            color: '#FFF',
          }}
        >{`Total Projects: ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.any,
  payload: PropTypes.any,
};

const customLegendcss = css`
position: relative;
cursor: default;
width: auto;
height= 300px;
background: rgb(255, 255, 255);
border-radius: 6px;
font-size: small;
font-family: 'GroldRegular';
text {
  font-family: 'GroldRegular';
}
ul {
  li{
    float: left;
    margin-bottom: 3.4%;
    width: 100%;
    text-align: left;
  }
}
`;

const linearData = [
  { name: 'Jan', projectCount: 40 },
  { name: 'Feb', projectCount: 70 },
  { name: 'Mar', projectCount: 110 },
  { name: 'Apr', projectCount: 100 },
  { name: 'May', projectCount: 170 },
  { name: 'Jun', projectCount: 70 },
  { name: 'Jul', projectCount: 90 },
  { name: 'Aug', projectCount: 120 },
  { name: 'Sep', projectCount: 60 },
  { name: 'Oct', projectCount: 30 },
  { name: 'Nov', projectCount: 140 },
  { name: 'Dec', projectCount: 90 },
];

const getDesc = str => {
  if (str && str.length && str.length > 51) {
    return str.substr(0, 51) + '...';
  }
  return str;
};

export default class ProjectCountByYearChart extends PureComponent {
  constructor(props) {
    super(props);
  }

  handleSectorPieClick = props => {
    if (
      props.payload &&
      props.payload.companySectorCodeID &&
      props.payload.countryID
    ) {
      navigate(
        `${this.props.programUrl}/projects?Country=${props.payload.countryID}&Sector=${props.payload.companySectorCodeID}::`
      );
    }
  };

  renderLegendText = (value, props) => {
    const data = (props || {}).payload;
    return <span>{getDesc((data || {}).sectorDescription)}</span>;
  };

  render() {
    return (
      <div css={customLegendcss}>
        <ResponsiveContainer height={340} width="100%">
          <LineChart width={600} height={300} data={linearData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
            <YAxis />
            <Tooltip
              cursor={{ fill: 'transparent' }}
              content={CustomTooltip}
              wrapperStyle={{
                backgroundColor: '#73a8e7',
                padding: '2px 10px',
                borderRadius: '5px',
                maxWidth: '300px',
                boxShadow: '0 1px 4px 0 rgba(0,0,0,0.14)',
              }}
            />
            <Line
              type="monotone"
              dataKey="projectCount"
              stroke="#3DCC91"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

ProjectCountByYearChart.propTypes = {
  sectorData: PropTypes.any,
  countryID: PropTypes.any,
  programUrl: PropTypes.any,
};
