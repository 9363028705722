// Filter Projects

const getValue = value =>
  typeof value === 'string' ? value.toUpperCase() : value;

function getName(key) {
  const properties = {
    COUNTRY: 'projectCountryName',
    SECTOR: 'projectSectorDescription',
    PROJECTOPPORTUNITYTYPE: 'projectOpportunityType',
    SDG: 'sdg',
    TOTALINVESTMENTCOST: 'totalInvestmentCost',
    SEARCH: 'search',
    FAVORITES: 'Favorites',
    PROJECTTITLE: 'projectTitle',
    'INVESTMENT COST': 'totalInvestmentCost',
    AREA: 'projectCountryName',
    SECTORTITLE: 'projectSectorDescription',
    SOURCE: 'campaignSourceName',
  };
  return properties[key.toUpperCase()] || 'none';
}

const searchStringKeys = [
  'projectTitle',
  'projectSectorDescription',
  'projectCountryName',
  'projectRegionName',
  'projectCityName',
];

const filterArray = (projects, filters) => {
  const filterKeys = Object.keys(filters);
  return projects.filter(project => {
    // validates all filter criteria
    return filterKeys.every(key => {
      // ignores an empty filter
      return filters[key].find(value => {
        if (
          key === 'TOTALINVESTMENTCOST' &&
          filters[key][0] <= project[getName(key)] &&
          filters[key][1] >= project[getName(key)]
        ) {
          return true;
        } else if (
          key === 'SEARCH' &&
          searchStringKeys.some(stringKey =>
            project[stringKey]
              .toString()
              .toUpperCase()
              .includes(value.toUpperCase())
          )
        ) {
          return true;
        } else if (key === 'FAVORITES' && project.projectID === value) {
          return true;
        } else if (
          key === 'SECTOR' &&
          (project.projectISICCodes || []).some(isic =>
            getValue(isic).startsWith(getValue(value))
          )
        ) {
          return true;
        } else if (
          key === 'SDG' &&
          (project.sdgiDs || []).some(
            sdgid => getValue(sdgid) === getValue(value)
          )
        ) {
          return true;
        } else if (getValue(value) === getValue(project[getName(key)])) {
          return true;
        }
      }) !== undefined
        ? true
        : false;
    });
  });
};

const filters = {
  Country: {
    title: 'Country',
    filterKey: 'COUNTRY',
    selector: item => item && item.countryName,
    finder: (collection, value) => collection.countryID === parseInt(value),
  },
  TotalInvestmentCost: {
    title: 'Investment Cost',
    filterKey: 'TOTALINVESTMENTCOST',
    selector: item => item,
    finder: (collection, value) =>
      collection.projectOpportunityTypeID === parseInt(value),
  },
  Sector: {
    title: 'Sector',
    filterKey: 'SECTOR',
    selector: item => item && item.isicCode,
    finder: (collection, value) =>
      collection.companySectorCodeID === parseInt(value),
  },
  InvestmentType: {
    title: 'Investment Type',
    filterKey: 'PROJECTOPPORTUNITYTYPE',
    selector: item => item && item.projectOpportunityTypeName,
    finder: (collection, value) =>
      collection.projectOpportunityTypeID === parseInt(value),
  },
  sdg: {
    title: 'SDG',
    filterKey: 'SDG',
    selector: item => item && item.sdgid,
    finder: (c, value) => c.sdgid === parseInt(value),
  },
  search: {
    title: 'Search',
    filterKey: 'SEARCH',
    selector: item => item,
    finder: (c, value) => c.sdgid === parseInt(value),
  },
  Favorites: {
    title: 'Favorites',
    filterKey: 'FAVORITES',
    selector: item => item,
    finder: (c, value) => value,
  },
  Source: {
    title: 'Source',
    filterKey: 'SOURCE',
    selector: item => item && item.campaignSourceName,
    finder: (c, value) => c.campaignSourceID === parseInt(value),
  },
};

const filterOfflineProjects = (
  projects,
  filterParams,
  filterMasterData,
  favoriteProjects
) => {
  const { Filters } = filterParams;

  const filterLabels = Object.keys(Filters || {})
    .map(searchKey => ({ searchKey, filter: filters[searchKey] }))
    .map(({ searchKey, filter }) => ({
      [filter.filterKey]:
        searchKey === 'TotalInvestmentCost' || searchKey === 'search'
          ? Filters[searchKey]
          : searchKey === 'Favorites'
          ? favoriteProjects
          : [
              ...(Filters[searchKey] || []).map(value =>
                (
                  (filterMasterData || {})[filter.filterKey] || []
                ).find(master => filter.finder(master, value))
              ),
            ].map(filter.selector),
    }))
    .reduce((filterElements, filter) => {
      return { ...filterElements, ...filter };
    }, {});
  projects = filterArray(projects, filterLabels);
  projects = paginateProjects(projects, filterParams);
  return projects;
};

const paginateProjects = (projects, filterParams) => {
  const total = projects.length;

  const { PageNumber, PageSize, SortBy, SortOrder } = filterParams;

  projects.sort((a, b) => {
    return SortOrder === 'asc'
      ? getValue(SortBy) === 'TOTALINVESTMENTCOST'
        ? a[getName(getValue(SortBy))] - b[getName(getValue(SortBy))]
        : getValue(SortBy) === 'SECTORTITLE'
        ? ((a[getName(getValue(SortBy))] || [])[0] || '').localeCompare(
            (b[getName(getValue(SortBy))] || [])[0] || ''
          )
        : a[getName(getValue(SortBy))].localeCompare(
            b[getName(getValue(SortBy))]
          )
      : getValue(SortBy) === 'TOTALINVESTMENTCOST'
      ? b[getName(getValue(SortBy))] - a[getName(getValue(SortBy))]
      : getValue(SortBy) === 'SECTORTITLE'
      ? ((b[getName(getValue(SortBy))] || [])[0] || '').localeCompare(
          (a[getName(getValue(SortBy))] || [])[0] || ''
        )
      : b[getName(getValue(SortBy))].localeCompare(
          a[getName(getValue(SortBy))]
        );
  });

  projects = projects.splice(PageSize * (PageNumber - 1), PageSize);

  return { items: projects, total };
};

export default filterOfflineProjects;
