import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Brush,
} from 'recharts';
import { css } from '@emotion/react';

const CustomTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <div
        className="custom-tooltip"
        style={{ padding: '0px', textAlign: 'left' }}
      >
        <p
          className="intro"
          style={{
            padding: '0px',
            margin: '0',
            textAlign: 'left',
            fontSize: '12px',
            color: '#FFF',
          }}
        >{`${payload[0].payload.name}`}</p>
        <p
          className="intro"
          style={{
            padding: '0px',
            margin: '0',
            textAlign: 'left',
            fontWeight: '600',
            color: '#FFF',
          }}
        >{`Total Projects: ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.any,
  payload: PropTypes.any,
};

const customLegendcss = css`
position: relative;
cursor: default;
width: auto;
height= auto;
background: rgb(255, 255, 255);
border-radius: 0px 0px 6px 6px;
font-family: 'GroldRegular';
text {
  font-family: 'GroldRegular';
  color: #3c4858;
  font-size: 12px;
  text-align: left;
  margin-bottom: 0;
  font-weight: 500;
  vertical-align: baseline;
}
ul {
  li{
    float: left;
    margin-bottom: 3.4%;
    width: 100%;
    text-align: left;
  }
}
.recharts-bar-rectangle{
    cursor: pointer;
  }
`;
const countryProjCountData = [
  { name: 'Cameroon', projectCount: 3, countryId: 151 },
  { name: 'Dominican-Republic', projectCount: 4, countryId: 191 },
  { name: 'Ethiopia', projectCount: 21, countryId: 91 },
  { name: 'Ghana', projectCount: 5, countryId: 55 },
  { name: 'Kenya', projectCount: 3, countryId: 169 },
  { name: 'Papua-New-Guinea', projectCount: 4, countryId: 75 },
  { name: 'Senegal', projectCount: 3, countryId: 182 },
  // { name: 'Tanzania', projectCount: 0, countryId: 120 },
  { name: 'Zambia', projectCount: 1, countryId: 82 },
];

const getDesc = str => {
  if (str && str.length && str.length > 51) {
    return str.substr(0, 51) + '...';
  }
  return str;
};

export default class ProjectCountByCountry extends PureComponent {
  constructor(props) {
    super(props);
  }

  handleCountryNameClick = props => {
    if (props.payload && props.payload.countryId) {
      navigate(
        `${this.props.programUrl}/projects?Country=${props.payload.countryId}:`
      );
    }
  };

  renderLegendText = (value, props) => {
    const data = (props || {}).payload;
    return <span>{getDesc((data || {}).sectorDescription)}</span>;
  };

  render() {
    return (
      <div css={customLegendcss}>
        <ResponsiveContainer height={400} width="100%">
          <BarChart
            width={600}
            height={400}
            data={countryProjCountData}
            margin={{ top: 30, right: 5, left: 1, bottom: 5 }}
          >
            <CartesianGrid
              vertical={false}
              strokeWidth={0.1}
              strokeDasharray="1 3"
            />
            <XAxis dataKey="name" fontWeight={600} />
            <YAxis />
            <Tooltip
              cursor={{ fill: 'transparent' }}
              content={CustomTooltip}
              wrapperStyle={{
                backgroundColor: '#73a8e7',
                padding: '2px 10px',
                borderRadius: '5px',
                maxWidth: '300px',
                boxShadow: '0 1px 4px 0 rgba(0,0,0,0.14)',
              }}
            />
            <Bar
              barSize={25}
              dataKey="projectCount"
              fill="#3DCC91"
              label={{
                position: 'top',
                fontSize: 12,
                fill: '#696666',
                fontWeight: '500',
              }}
              onClick={this.handleCountryNameClick}
            />
            <Brush dataKey="name" height={30} stroke="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

ProjectCountByCountry.propTypes = {
  countryId: PropTypes.any,
  programUrl: PropTypes.any,
};
