import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
// core components
import tableStyle from '../../assets/jss/styles/components/tableStyle';
import TablePaginationActions from './TablePaginationActions';
import NoProjects from '../NoProjects';
import { useTranslation } from 'react-i18next';
import SourceChip from '../SourceChip';
import { numFormatter } from '../../shared/numberFormatter';
import { isEmpty } from 'lodash';

function CustomTable({ ...props }) {
  const { t } = useTranslation();
  const {
    classes,
    tableHead,
    tableData,
    tableHeaderColor,
    totalProjects,
    onPageSizeChange,
    onSortByChange,
    onSortOrderChange,
    onPageNumberChange,
    isLoading,
    fallback,
    programLink,
    favoriteProjects,
    handleFavoriteProjects,
    pageNumber,
    pageSize,
  } = props;

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Project');

  // useEffect(() => {
  //   onPageSizeChange(0);
  // }, [isFavoritesView]);

  const handleChangeRowsPerPage = event => {
    onPageSizeChange(parseInt(event.target.value, 10));
  };

  const getAppendedCommaValue = value => {
    let output = '';
    if (value) {
      output = value + ',';
    }
    return output;
  };

  const getCostValue = value => {
    let output = '0 EUR';
    if (value) {
      output = (numFormatter(value) || '').toLocaleString() + ' EUR';
    }
    return output;
  };

  const handleSortByChange = property => () => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
    onSortByChange(getNameByTitle(property));
    onSortOrderChange(isDesc ? 'asc' : 'desc');
  };

  const handlePageNumberChange = (event, page) => {
    onPageNumberChange(page + 1);
  };

  const getNameByTitle = title => {
    if (title) {
      if (title === 'Project') {
        return 'ProjectTitle';
      } else if (title === 'Investment Cost') {
        return 'TotalInvestmentCost';
      } else if (title === 'Area') {
        return 'Area';
      } else if (title === 'Sector') {
        return 'Sector';
      }
    }
  };

  return isLoading ? (
    <div>{fallback}</div>
  ) : (
    <div className={classes.tableResponsive}>
      {!isEmpty(tableData) && totalProjects > 0 ? (
        <Table className={classes.table}>
          {tableHead !== undefined ? (
            <TableHead className={classes[tableHeaderColor + 'TableHeader']}>
              <TableRow className={classes.tableHeadRow}>
                {(tableHead || []).map((name, key) => {
                  return (
                    <TableCell
                      className={
                        classes.tableCell + ' ' + classes.tableHeadCell
                      }
                      key={key}
                    >
                      <TableSortLabel
                        active={orderBy === name}
                        direction={order}
                        onClick={handleSortByChange(name)}
                      >
                        {name}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {(tableData || []).map((project, key) => {
              return (
                !project.isSEZ && (
                  <TableRow
                    hover
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      navigate(
                        `${programLink}/projectDetails/?id=${project.projectID}`
                      )
                    }
                    key={key}
                    className={classes.tableBodyRow}
                  >
                    <TableCell className={classes.tableCell}>
                      <Tooltip
                        title={project.projectTitle}
                        placement="top-start"
                      >
                        <span>{project.projectTitle}</span>
                      </Tooltip>
                      <span className="cellContents">
                        <span className="favIcon">
                          {(favoriteProjects || []).indexOf(project.projectID) >
                          -1 ? (
                            <Tooltip
                              title={t('remove_favorites')}
                              placement="top-start"
                            >
                              <StarOutlinedIcon
                                onClick={e => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleFavoriteProjects(
                                    project.projectID,
                                    false
                                  );
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={t('add_favorites')}
                              placement="top-start"
                            >
                              <StarBorderOutlinedIcon
                                onClick={e => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleFavoriteProjects(
                                    project.projectID,
                                    true
                                  );
                                }}
                              />
                            </Tooltip>
                          )}
                        </span>
                        <span className="sourceLabel">
                          {project.isYouthEmpowerment ? (
                            <SourceChip
                              style="margin-right: 8px"
                              sourceName={t('youth')}
                            />
                          ) : null}
                          {project.isWomenEmpowerment ? (
                            <SourceChip sourceName={t('women')} />
                          ) : null}
                        </span>
                      </span>
                    </TableCell>
                    <TableCell className={classes.tableCellNumber}>
                      {getCostValue(project.totalInvestmentCost)}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {getAppendedCommaValue(project.projectCityName)}
                      {getAppendedCommaValue(project.projectRegionName)}
                      {project.projectCountryName}
                    </TableCell>

                    <TableCell className={classes.tableCell}>
                      {project ? (
                        <Tooltip
                          title={
                            project.projectSectorDescription &&
                            project.projectSectorDescription.length > 0
                              ? project.projectSectorDescription
                              : 'NA'
                          }
                          placement="top-start"
                        >
                          <span>
                            {project.projectSectorDescription &&
                            project.projectSectorDescription.length > 0
                              ? project.projectSectorDescription
                              : 'NA'}
                          </span>
                        </Tooltip>
                      ) : null}
                    </TableCell>
                  </TableRow>
                )
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, 40]}
                colSpan={4}
                count={totalProjects}
                rowsPerPage={pageSize}
                page={pageNumber - 1}
                SelectProps={{
                  inputProps: { 'aria-label': t('projects_per_page') },
                  native: true,
                }}
                labelRowsPerPage={t('projects_per_page')}
                onChangePage={handlePageNumberChange}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      ) : (
        <NoProjects data="Projects" />
      )}
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: 'gray',
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.object),
  onSortByChange: PropTypes.any,
  onSortOrderChange: PropTypes.any,
  onPageNumberChange: PropTypes.any,
  onPageSizeChange: PropTypes.any,
  totalProjects: PropTypes.any,
  isLoading: PropTypes.bool,
  fallback: PropTypes.element,
  programLink: PropTypes.string,
  programCode: PropTypes.string,
  handleFavoriteProjects: PropTypes.func,
  favoriteProjects: PropTypes.array,
  isFavoritesView: PropTypes.bool,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
};

export default withStyles(tableStyle)(CustomTable);
