import 'rc-slider/assets/index.css';
import React from 'react';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import styles from '../styles/projects';

const { costSlider } = styles;

const RangeSlider = ({ value, min, max, onChange, step = 100 }) => {

  return (
    <div>
      <Slider css={costSlider} value={value} min={min} max={max} onChange={onChange} step={max/10} marks/>
    </div>
  );
};

RangeSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default RangeSlider;
