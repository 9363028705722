import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import GridContainer from '../Grid/GridContainer.jsx';
import GridItem from '../Grid/GridItem.jsx';
import Image from '../Image';
import LocationOn from '@material-ui/icons/LocationOn';
import Visibility from '@material-ui/icons/Visibility';
import Dollar from '@material-ui/icons/EuroSymbol';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '../Table/TablePaginationActions';
import NoProjects from '../NoProjects';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import IconButton from '@material-ui/core/IconButton';
import { ISICCodes, PROJECT_STOCK_IMAGES } from '../../shared/constants';
import { numFormatter } from '../../shared/numberFormatter';
import { findIndex, findKey } from 'lodash';

const GridView = ({ ...props }) => {
  const { t } = useTranslation();
  const {
    gridData,
    totalProjects,
    isLoading,
    programLink,
    favoriteProjects,
    handleFavoriteProjects,
    fallback,
    pageNumber,
    pageSize,
  } = props;

  const handlePageNumberChange = (event, page) => {
    props.onPageNumberChange(page + 1);
  };

  const handleChangeRowsPerPage = event => {
    props.onPageSizeChange(parseInt(event.target.value, 10));
  };

  const getAppendedCommaValue = value => {
    let output = '';
    if (value) {
      output = value + ', ';
    }
    return output;
  };

  const getCostValue = value => {
    let output = '0 EUR';
    if (value) {
      output = (numFormatter(value) || '') + ' EUR';
    }
    return output;
  };

  const getImageName = (isicCode, projectId) => {
    // if (isicCode) {

    // console.log('gridData', gridData);
    let imageIndex = findIndex(gridData, function(prj) {
      return prj?.projectID == projectId;
    });

    let sector = findKey(ISICCodes, function(v) {
      return v == isicCode?.[0];
    });

    let imageNameArray =
      PROJECT_STOCK_IMAGES[ISICCodes[sector]] ||
      PROJECT_STOCK_IMAGES[ISICCodes.UNKNOWN];

    let imageName = imageNameArray[imageIndex % imageNameArray.length];

    return imageName;
    // }

    // let output = '';
    // if (isicCode) {
    //   if (isicCode.startsWith(ISICCodes.FISHING)) {
    //     output = 'Fishing.jpeg';
    //   } else if (isicCode.startsWith(ISICCodes.MINING)) {
    //     output = 'Mining.jpeg';
    //   } else if (isicCode.startsWith(ISICCodes.ELECTRICITY)) {
    //     output = 'ElectricityGasSteamWater.jpeg';
    //   } else if (isicCode.startsWith(ISICCodes.ENTERTAINMENT)) {
    //     output = 'Entertainment.jpeg';
    //   } else if (isicCode.startsWith(ISICCodes.HEALTH)) {
    //     output = 'HealthAndSocialWork.jpeg';
    //   } else if (isicCode.startsWith(ISICCodes.HOTEL)) {
    //     output = 'HotelsAndRestaurant.jpeg';
    //   } else if (isicCode.startsWith(ISICCodes.REALESTATE)) {
    //     output = 'RealEstate.jpeg';
    //   } else if (isicCode.startsWith(ISICCodes.TRANSPORT)) {
    //     output = 'TransportStorage.jpg';
    //   } else if (isicCode.startsWith(ISICCodes.AGRICULTURE)) {
    //     output = 'AgricultureHunting.jpeg';
    //   } else if (isicCode.startsWith(ISICCodes.MANUFACTURING)) {
    //     output = 'Manufacturing.jpeg';
    //   } else {
    //     output = 'Manufacturing.png';
    //   }
    // }
    // return output;
  };

  return isLoading ? (
    <div>{fallback}</div>
  ) : (
    <GridContainer>
      {gridData && gridData.filter(gd => !gd.isSEZ).length > 0
        ? [
            ...(gridData || []).map(
              (project, key) =>
                !project.isSEZ && (
                  <GridItem xs={12} sm={6} md={6} xl={6} key={key}>
                    <Link
                      to={`${programLink}/projectDetails/?id=${project.projectID}&view=${key}`}
                    >
                      <Card className="project-card">
                        <CardActionArea>
                          <div className="project-image">
                            {project.projectLogo &&
                            project.projectLogo.length > 0 ? (
                              <img
                                alt="default Project"
                                src={project.projectLogo}
                              ></img>
                            ) : (
                              <Image
                                imageName={getImageName(
                                  project.projectISICCodes
                                    ? project.projectISICCodes[0]
                                    : '',
                                  project.projectID
                                )}
                              />
                            )}
                          </div>
                          <div className="project-content">
                            <CardContent className="card-details">
                              <div className="views-source-container">
                                <div className="views-container">
                                  <div className="left">
                                    <Visibility />
                                    <span>
                                      {' ' +
                                        project.projectViewCount?.toLocaleString() +
                                        ` ${t('views')}`}
                                    </span>
                                  </div>
                                  {(favoriteProjects || []).indexOf(
                                    project.projectID
                                  ) > -1 ? (
                                    <Tooltip
                                      title={t('remove_favorites')}
                                      placement="top-start"
                                    >
                                      <StarOutlinedIcon
                                        onClick={e => {
                                          e.preventDefault();
                                          handleFavoriteProjects(
                                            project.projectID,
                                            false
                                          );
                                        }}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      title={t('add_favorites')}
                                      placement="top-start"
                                    >
                                      <StarBorderOutlinedIcon
                                        onClick={e => {
                                          e.preventDefault();
                                          handleFavoriteProjects(
                                            project.projectID,
                                            true
                                          );
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </div>
                                {project.isYouthEmpowerment ? (
                                  <div className="source-container">
                                    <span>{t('youth')}</span>
                                  </div>
                                ) : null}
                                {project.isWomenEmpowerment ? (
                                  <div className="source-container women">
                                    <span>{t('women')}</span>
                                  </div>
                                ) : null}
                              </div>
                              <div className="description-container">
                                <div className="project-name">
                                  <Tooltip
                                    title={project.companyName}
                                    placement="top-start"
                                  >
                                    <p>{project.companyName}</p>
                                  </Tooltip>
                                </div>
                                <div className="project-title">
                                  <Tooltip
                                    title={
                                      project.projectOpportunityType +
                                      (project.projectOpportunityScope
                                        ? '/' + project.projectOpportunityScope
                                        : '')
                                    }
                                    placement="top-start"
                                  >
                                    <h4>{`${project.projectOpportunityType} ${
                                      project.projectOpportunityScope
                                        ? '/' + project.projectOpportunityScope
                                        : ''
                                    }`}</h4>
                                  </Tooltip>
                                </div>
                                <div className="project-description">
                                  <Tooltip
                                    title={project.projectTitle}
                                    placement="top-start"
                                  >
                                    <p>{project.projectTitle}</p>
                                  </Tooltip>
                                </div>
                                <div className="project-location">
                                  <Tooltip
                                    title={
                                      getAppendedCommaValue(
                                        project.projectCityName
                                      ) +
                                      getAppendedCommaValue(
                                        project.projectRegionName
                                      ) +
                                      project.projectCountryName
                                    }
                                    placement="top-start"
                                  >
                                    <p>
                                      <LocationOn />
                                      {getAppendedCommaValue(
                                        project.projectCityName
                                      )}
                                      {getAppendedCommaValue(
                                        project.projectRegionName
                                      )}
                                      {project.projectCountryName}
                                    </p>
                                  </Tooltip>
                                </div>
                                <div className="project-cost">
                                  <p>
                                    <Dollar />
                                    {getCostValue(project.totalInvestmentCost)}
                                  </p>
                                </div>
                              </div>
                            </CardContent>
                            <CardActions className="project-action">
                              <IconButton>
                                {' '}
                                <ArrowRightAltIcon />
                              </IconButton>
                            </CardActions>
                          </div>
                        </CardActionArea>
                      </Card>
                    </Link>
                  </GridItem>
                )
            ),
            <GridContainer key="pager">
              <GridItem xs={12} sm={12} md={12}>
                <Table>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 20, 30]}
                        colSpan={3}
                        count={totalProjects}
                        rowsPerPage={pageSize}
                        page={pageNumber - 1}
                        SelectProps={{
                          inputProps: { 'aria-label': t('projects_per_page') },
                          native: true,
                        }}
                        labelRowsPerPage={t('projects_per_page')}
                        onChangePage={handlePageNumberChange}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </GridItem>
            </GridContainer>,
          ]
        : !isLoading && <NoProjects data="Projects" />}
    </GridContainer>
  );
};

GridView.propTypes = {
  gridData: PropTypes.any,
  totalProjects: PropTypes.number,
  onPageNumberChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  isLoading: PropTypes.bool,
  fallback: PropTypes.element,
  programLink: PropTypes.string,
  programCode: PropTypes.string,
  handleFavoriteProjects: PropTypes.func,
  favoriteProjects: PropTypes.array,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
};

export default GridView;
